import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import Loading from "../components/Loading";
import Calendar from "../components/Calendar";
import "../css/adminCalendar.css";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import TimeSlot from "../components/TimeSlot";
import { usePostContext } from "../contexts/PostContext";
import { useAdminContext } from "../contexts/AdminContext";
import { Link, useNavigate } from "react-router-dom";
const DisableSlots = () => {
  const { currentColor } = useStateContext();
  const { DisableDay } = usePostContext();
  const [btnLoading, setBtnLoading] = useState(null);
  const { fetchDisabledDays } = useFetchContext();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    let dataToSend = {
      date: moment(values.date).format("YYYY-MM-DD"),
    };
    setBtnLoading(true);
    await DisableDay(dataToSend);
    await fetchDisabledDays();
    formik.resetForm();
    navigate("/disabled-days");
    setBtnLoading(false);
  };

  const DisableDaySchema = Yup.object({
    date: Yup.string().required("Please Select Product"),
  });

  const formik = useFormik({
    initialValues: {
      date: null,
    },
    validationSchema: DisableDaySchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleDateChange = (value) => {
    formik.setFieldValue("date", value);
  };

  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex items-center justify-between w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font tracking-widest">
            Disable Day
          </h1>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex-col py-10 mx-auto max-w-3xl"
        >
          <div className=" min-h-max w-full">
            <Calendar onDateChange={handleDateChange} minDate={new Date()} />
            {formik.touched.date && formik.errors.date && (
              <div className="mt-3 text-body-heading font-semibold">
                {formik.errors.date}
              </div>
            )}
          </div>
          <div className="w-full flex justify-end my-6">
            <button
              type="submit"
              style={{ backgroundColor: currentColor }}
              disabled={!formik.values.date || btnLoading}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              {btnLoading ? "Submitting..." : "Disable"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default DisableSlots;
