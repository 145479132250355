import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
const TotalOrder = ({ packageName, date, price, id, time }) => {
  const { currentColor } = useStateContext();
  return (
    <Link
      to={`/booking/${id}`}
      className="py-8 flex flex-col h-full shadow-sm md:max-h-96 md:flex-row w-full"
    >
      <div className="md:ml-3 rounded-md bg-white dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-2 m-0 w-full">
        <div className="flex text-xs md:text-sm flex-col">
          <h2
            style={{ color: currentColor }}
            className="text-xl md:text-2xl font-medium title-font my-2"
          >
            {packageName}
          </h2>
          <div className="grid grid-cols-2 ">
            <div>Date</div>
            <div className="">{moment(date).format("ll")}</div>
          </div>
          <div className="grid grid-cols-2 ">
            <div>Day</div>
            <div className="">{moment(date).format("dddd")}</div>
          </div>
          <div className="grid grid-cols-2 ">
            <div>Time</div>
            <div className="">{time}</div>
          </div>
          <div className="grid grid-cols-2 ">
            <div>Price</div>
            <div className="">{price} $</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TotalOrder;
