import React from "react";
import { Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import Category from "../components/Category";
import Loading from "../components/Loading";
const Categories = () => {
  const { currentMode } = useStateContext();
  const { categories } = useFetchContext();
  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font tracking-widest">
            Categories
          </h1>
          <div className="flex items-center justify-center">
            <Link
              to={"/create-category"}
              className="py-1 md:py-2 dark:text-black-shade text-white-shade px-5 rounded-md dark:bg-white-shade bg-black-shade"
            >
              <span className="text-xs md:text-sm font-bold">Add Category</span>
            </Link>
          </div>
        </div>
        {categories === null ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loading
              color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
              bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
            />
          </div>
        ) : (
          <div className="flex flex-wrap -m-2">
            {categories?.map((room) => (
              <Category id={room._id} key={room._id} roomName={room.name} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Categories;
