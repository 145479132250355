import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthContext";
import { baseUrl } from "../data/constant";

const INITIAL_STATES = {
  users: null,
  categories: null,
  packages: null,
  classes: null,
  bookings: null,
  totalEarnings: null,
  weeklyEarnings: null,
  recentTransactions: null,
  weeklyStats: null,
  refundedAmount: null,
  disabledDays: null,
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
};

export const FetchContext = createContext(INITIAL_STATES);

export const FetchContextProvider = ({ children }) => {
  const [dataState, setDataState] = useState(INITIAL_STATES);
  const { user, token } = useAuthContext();

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/category/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, categories: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchPackages = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/package/all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, packages: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchClasses = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/class/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, classes: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchAllBookings = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/booking/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, bookings: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchSinglePackage = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/package/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchSingleClass = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/class/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchSingleBooking = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/booking/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchAllUsers = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/user/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, users: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchSingleUser = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/user/single/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchWeeklyEarnings = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/weekly-earnings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          weeklyEarnings: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchTotalEarnings = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/total-earnings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          totalEarnings: data.totalRevenue,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchRecentTransactions = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/recent-transaction`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          recentTransactions: data.bookings,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchWeeklyStats = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/weekly-package`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          weeklyStats: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchRefundedAmount = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/refund-amount`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          refundedAmount: data.refundedAmount,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchDisabledDays = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/disabled/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({
          ...prevData,
          disabledDays: data.data,
        }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
      setDataState((prevData) => ({
        ...prevData,
        disabledDays: [],
      }));
    }
  };
  useEffect(() => {
    user && fetchCategories();
    user && fetchPackages();
    user && fetchClasses();
    user && fetchAllUsers();
    user && fetchAllBookings();
    user && fetchTotalEarnings();
    user && fetchWeeklyEarnings();
    user && fetchRecentTransactions();
    user && fetchWeeklyStats();
    user && fetchDisabledDays();
    user && fetchRefundedAmount();
  }, [user]);

  return (
    <FetchContext.Provider
      value={{
        ...dataState,
        fetchCategories,
        fetchPackages,
        fetchClasses,
        fetchSinglePackage,
        fetchAllUsers,
        fetchSingleClass,
        fetchSingleUser,
        fetchSingleBooking,
        fetchDisabledDays,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};

export const useFetchContext = () => useContext(FetchContext);
