import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../contexts/AuthContext";
import logo from "../data/logo.png";
import Loading from "../components/Loading";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = () => {
  const { login, loading, success } = useAuthContext();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (values) => {
    await login(values);
    if (success) {
      navigate(-1);
    }
  };
  //FORM SCHEMA FOR FORMIK
  const UserLogin = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string()
      .min(6, "Minimum 6 Characters")
      .required("Password is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      email: "test3@test.net",
      password: "testing123",
      authType: "email",
    },
    validationSchema: UserLogin,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className="h-screen bg-black-shade w-screen flex items-center justify-center">
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-full w-auto"
            src={logo}
            alt="Longevity Lounge"
          />
          <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-app-blue">
            Sign in to your account
          </h2>
        </div>
        <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={formik.handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-app-blue"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                  className="block w-full outline-none bg-app-dark-blue px-1 rounded-md border-0 py-1.5 text-app-blue shadow-sm ring-1 ring-inset ring-app-blue placeholder:text-white sm:text-sm sm:leading-6"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-app-blue text-xs mt-1">
                    {formik.errors.email}
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-app-blue"
              >
                Password
              </label>
              <div className="mt-2">
                <div className="relative">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"} // Show text or password based on state
                    autoComplete="current-password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className="block w-full outline-none bg-app-dark-blue text-app-blue px-1 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-app-blue placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex cursor-pointer items-center">
                    {showPassword ? (
                      <FiEyeOff
                        className="text-app-blue "
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <FiEye
                        className="text-app-blue "
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="text-app-blue text-xs mt-1">
                    {formik.errors.password}
                  </div>
                )}
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={loading ? true : false}
                className="flex w-full justify-center disabled:border-app-dark-blue rounded-md bg-app-blue px-3 py-1.5 text-sm font-semibold leading-6 text-app-dark-blue shadow-sm"
              >
                {loading ? (
                  <Loading
                    variant={"round"}
                    color={"black"}
                    bgColor={"#c78c4e"}
                    width={"1rem"}
                  />
                ) : (
                  "Sign in"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
