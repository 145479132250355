import { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { usePostContext } from "../contexts/PostContext";
import { useFetchContext } from "../contexts/FetchContext";
const CreatePackage = () => {
  const { currentColor } = useStateContext();
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const { PostPackage } = usePostContext();
  const { categories } = useFetchContext();
  const handleSubmit = async (values) => {
    setBtnLoading(true);
    await PostPackage(values);
    formik.resetForm();
    setBtnLoading(false);
    navigate("/packages");
  };

  const PackageSchema = Yup.object({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    price: Yup.number().required("Required"),
    description: Yup.string()
      .min(2, "Too Short!")
      .max(300, "Too Long!")
      .required("Required"),
    duration: Yup.string()
      .min(2, "Too Short!")
      .max(300, "Too Long!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      categoryId: "",
      name: "",
      price: "",
      description: "",
      duration: "",
    },
    validationSchema: PackageSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <section className="dark:text-white-shade text-black-shade body-font overflow-hidden">
      <div className="container px-5 py-14 mx-auto">
        <h1
          style={{ color: currentColor }}
          className="text-3xl text-center font-medium title-font mb-5 tracking-widest"
        >
          Create Package
        </h1>
        <div className="mt-4 lg:mt-0 w-full lg:py-6 mb-6 lg:mb-0">
          <form onSubmit={formik.handleSubmit} className="max-w-xl mx-auto">
            <div className="flex items-center gap-5">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="categoryId" className="sr-only">
                  Select Category
                </label>
                <select
                  id="categoryId"
                  value={formik.values.categoryId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
                >
                  <option
                    className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                    value=""
                  >
                    Select Category
                  </option>
                  {categories?.map((category) => (
                    <option
                      className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                      key={category._id}
                      value={category._id}
                    >
                      {category.name}
                    </option>
                  ))}
                </select>
                {formik.touched.categoryId && formik.errors.categoryId && (
                  <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                    {formik.errors.categoryId}
                  </div>
                )}
              </div>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                placeholder=" "
                maxLength={50}
                required
              />
              <label
                htmlFor="name"
                className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Package Name
              </label>
              {formik.touched.name && formik.errors.name && (
                <div className="text-white-shade text-xs mt-1">
                  {formik.errors.name}
                </div>
              )}
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="number"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="price"
                className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Package Price
              </label>
              {formik.touched.price && formik.errors.price && (
                <div className="text-white-shade text-xs mt-1">
                  {formik.errors.price}
                </div>
              )}
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <input
                type="number"
                name="duration"
                value={formik.values.duration}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0  peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="duration"
                className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
              >
                Class Duration (Minutes)
              </label>
              {formik.touched.duration && formik.errors.duration && (
                <div className="text-white-shade text-xs mt-1">
                  {formik.errors.duration}
                </div>
              )}
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-black-shade dark:text-white-shade"
              >
                Description
              </label>
              <textarea
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows="4"
                className="block p-2.5 w-full text-sm text-black-shade bg-gray-50 rounded-lg border border-black-shade dark:bg-main-dark-bg dark:border-white-shade dark:placeholder-gray-300 placeholder-gray-700 dark:text-white-shade "
                placeholder="Add Description"
              ></textarea>
              {formik.touched.description && formik.errors.description && (
                <div className="text-white-shade text-xs mt-1">
                  {formik.errors.description}
                </div>
              )}
            </div>
            <div className=" w-full my-2 flex justify-end ">
              <button
                type="submit"
                style={{ backgroundColor: currentColor }}
                disabled={btnLoading}
                className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
              >
                {btnLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CreatePackage;
