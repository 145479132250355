import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";

// Main Calendar Component
const Calendar = React.memo(
  ({ onDateChange, minDate, maxDate, initialSelectedDate }) => {
    const today = moment();
    const [currentDate, setCurrentDate] = useState(today);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
      // Set initial selected date if provided
      if (initialSelectedDate) {
        setSelectedDate(moment(initialSelectedDate).format("YYYY-MM-DD"));
        setCurrentDate(moment(initialSelectedDate));
      }
    }, [initialSelectedDate]);

    // Helper to generate the days of the month
    const generateDays = (currentMoment) => {
      const daysInMonth = currentMoment.daysInMonth();
      const firstDay = currentMoment.startOf("month").day();
      const adjustedFirstDay = firstDay === 0 ? 7 : firstDay;

      const days = [];

      for (let i = 1; i < adjustedFirstDay; i++) {
        days.push(null);
      }

      for (let day = 1; day <= daysInMonth; day++) {
        days.push(day);
      }

      return days;
    };

    const days = useMemo(() => generateDays(currentDate), [currentDate]);

    const prevMonth = () => {
      setCurrentDate((prev) => prev.clone().subtract(1, "months"));
    };

    const nextMonth = () => {
      setCurrentDate((prev) => prev.clone().add(1, "months"));
    };

    const handleDayClick = (day) => {
      if (day && !isDisabledDay(day)) {
        const clickedDate = moment(currentDate).date(day).format("YYYY-MM-DD");

        setSelectedDate((prevSelected) => {
          const newSelectedDate =
            prevSelected === clickedDate ? null : clickedDate;

          if (onDateChange) {
            onDateChange(
              newSelectedDate
                ? moment(newSelectedDate, "YYYY-MM-DD").toDate()
                : null
            );
          }

          return newSelectedDate;
        });
      }
    };

    const isSelectedDay = (day) => {
      if (day === null) return false; // Handle null days (empty slots)
      const dateStr = moment(currentDate).date(day).format("YYYY-MM-DD");
      return selectedDate === dateStr;
    };

    const isDisabledDay = (day) => {
      if (day === null) return true; // Disable null days (empty slots)
      const dayDate = moment(currentDate).date(day).startOf("day");
      const minMoment = minDate ? moment(minDate).startOf("day") : null;
      const maxMoment = maxDate ? moment(maxDate).startOf("day") : null;

      return (
        (minMoment && dayDate.isBefore(minMoment)) ||
        (maxMoment && dayDate.isAfter(maxMoment))
      );
    };

    return (
      <div className="calendar bg-white rounded-lg shadow-lg w-full">
        {/* Calendar Header */}
        <div className="flex justify-around items-center py-2 mb-4">
          <button
            type="button"
            onClick={prevMonth}
            className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
            aria-label="Previous month"
          >
            {"<"}
          </button>

          <div className="text-lg font-bold text-center text-black">
            {currentDate.format("MMMM YYYY")}
          </div>

          <button
            type="button"
            onClick={nextMonth}
            className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
            aria-label="Next month"
          >
            {">"}
          </button>
        </div>

        {/* Calendar Days */}
        <div className="flex flex-col">
          {/* Day Names */}
          <div className="grid py-2 grid-cols-7">
            {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((d) => (
              <div key={d} className="text-center font-bold text-gray-700">
                {d}
              </div>
            ))}
          </div>

          {/* Day Numbers */}
          <div className="grid bg-gray-100 gap-px grid-cols-7 ">
            {days.map((day, index) => (
              <div
                key={index}
                className={`text-center p-2 cursor-pointer
              ${day ? "hover:bg-blue-200" : "invisible"}
              ${
                isSelectedDay(day)
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-800"
              }
              ${
                isDisabledDay(day)
                  ? "!bg-gray-200 !text-gray-500 cursor-not-allowed hover:bg-gray-200"
                  : ""
              }
            `}
                onClick={() => !isDisabledDay(day) && handleDayClick(day)}
              >
                {day}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export default Calendar;
