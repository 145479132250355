import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import BookingSession from "../components/BookingSession";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
import CustomModal from "../components/CustomModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";

const BookingDetail = () => {
  const { fetchSingleBooking } = useFetchContext();
  const {
    ReturnAppointmentPayment,
    acceptPendingBooking,
    rejectPendingBooking,
  } = usePostContext();
  const { currentMode } = useStateContext();
  const { id } = useParams();
  const { currentColor } = useStateContext();
  const [bookingDetails, setBookingDetails] = useState(null);
  console.log("🚀 ~ BookingDetail ~ bookingDetails:", bookingDetails);
  const [refundLoading, setRefundLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [priceToRefund, setPriceToRefund] = useState(false);
  const [refreshState, setRefreshState] = useState(false);
  const [pendingLoading, setPendingLoading] = useState({
    state: false,
    message: "",
  });
  const navigate = useNavigate();

  const handleOpen = (values) => {
    setPriceToRefund(values.priceToRefund);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlePendingAccept = async () => {
    setPendingLoading({ state: true, message: "Accepting" });
    let dataToSend = {
      bookingId: id,
    };
    await acceptPendingBooking(dataToSend);
    setPendingLoading({ state: false, message: "" });
    setRefreshState((prev) => !prev);
  };
  const handlePendingReject = async () => {
    setPendingLoading({ state: true, message: "Rejecting" });
    let dataToSend = {
      bookingId: id,
    };
    await rejectPendingBooking(dataToSend);
    setPendingLoading({ state: false, message: "" });
    navigate("/bookings");
  };

  useEffect(() => {
    (async () => {
      let response = await fetchSingleBooking(id);
      if (response?.success) {
        setBookingDetails(response?.data);
      }
    })();
  }, [refreshState]);

  const refundAmount = async () => {
    handleClose();
    setRefundLoading(true);
    let data = {
      paymentIntentId: bookingDetails.booking.paymentResult.id,
      priceToRefund,
      bookingId: bookingDetails.booking._id,
    };
    await ReturnAppointmentPayment(data);
    setRefundLoading(false);
    navigate("/bookings");
  };

  //FORM SCHEMA FOR FORMIK
  const RefundSchema = Yup.object({
    priceToRefund: Yup.number().required("Refund Amount is Required"),
  });

  //FORMIK INTIALIZATION FOR FORM
  const formik = useFormik({
    initialValues: {
      priceToRefund: "",
    },
    validationSchema: RefundSchema,
    onSubmit: (values) => {
      handleOpen(values);
    },
  });

  const getPartialAmount = () => {
    const paymentReceived =
      bookingDetails?.booking?.paymentResult?.paymentReceived;
    const refundedAmount =
      bookingDetails?.booking?.paymentResult?.refundedAmount;

    if (paymentReceived && refundedAmount != null) {
      return paymentReceived - refundedAmount;
    }

    return "--"; // Fallback value in case the required data is not available
  };

  return (
    <div className="container px-5 py-5 flex justify-center dark:text-white-shade text-black-shade items-center flex-col mx-auto">
      {bookingDetails === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <>
          <div
            className="p-4 mb-4 text-sm w-full text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
            role="alert"
          >
            {!bookingDetails?.booking?.paymentResult?.isRefunded ? (
              <form
                onSubmit={formik.handleSubmit}
                className="flex w-full items-center gap-x-4 justify-between"
              >
                <div className="relative z-0 w-full my-5 group">
                  <input
                    type="number"
                    name="priceToRefund"
                    className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade  focus:outline-none focus:ring-0 peer"
                    placeholder=""
                    value={formik.values.priceToRefund}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  <label
                    htmlFor="priceToRefund"
                    className="peer-focus:font-medium absolute text-sm text-black-shade dark:text-white-shade duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Refund Amount
                  </label>
                  {formik.touched.priceToRefund &&
                    formik.errors.priceToRefund && (
                      <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                        {formik.errors.priceToRefund}
                      </div>
                    )}
                </div>
                <button
                  type="submit"
                  disabled={refundLoading}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  {refundLoading ? "Refunding..." : "Refund"}
                </button>
              </form>
            ) : (
              <div className="text-lg font-medium">
                Amount Has Been Refunded
              </div>
            )}
          </div>
          {bookingDetails?.booking?.status === "pending" ? (
            <div
              className="p-4 mb-4 text-sm w-full flex flex-col gap-y-6 text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
              role="alert"
            >
              <span className="text-sm" style={{ color: currentColor }}>
                The booking is currently pending. Please accept or reject the
                request to notify the user about the status of their booking.
              </span>
              {pendingLoading.state ? (
                <div className="flex w-full items-center justify-center text-lg">
                  {pendingLoading.message}...
                </div>
              ) : (
                <div className="grid grid-cols-2">
                  <button
                    type="submit"
                    onClick={handlePendingAccept}
                    className="text-black-shade flex items-center justify-center gap-x-2 dark:hover:bg-white/10 hover:bg-black/10 disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    <IoIosCheckmark size={20} /> Accept
                  </button>
                  <button
                    type="submit"
                    onClick={handlePendingReject}
                    className="text-black-shade flex items-center justify-center gap-x-2  dark:hover:bg-white/10 hover:bg-black/10 disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    <IoIosClose size={20} /> Reject
                  </button>
                </div>
              )}
            </div>
          ) : bookingDetails?.booking?.status === "rejected" ? (
            <div
              className="p-4 mb-4 text-sm w-full flex flex-col gap-y-6 text-black-shade dark:text-white-shade rounded-lg bg-white dark:bg-secondary-dark-bg "
              role="alert"
            >
              <span className="text-base text-black-shade dark:text-white-shade">
                The booking is rejected.
              </span>
            </div>
          ) : null}

          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              Booking Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex text-xs md:text-sm flex-col">
                <h2
                  style={{ color: currentColor }}
                  className="text-xl md:text-2xl pb-4 font-medium title-font my-2"
                >
                  {bookingDetails?.booking?.packageId?.name}
                </h2>
                <div className="space-y-3 text-base">
                  <div className="grid grid-cols-2 ">
                    <div>Day</div>
                    <div className="">
                      {moment(bookingDetails?.booking?.selectedSlot?.date)
                        .utc()
                        .format("dddd")}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Time</div>
                    <div className="">
                      {bookingDetails?.booking?.selectedSlot?.time}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Price</div>
                    <div className="">
                      {bookingDetails?.booking?.paymentResult
                        ?.paymentReceived || "--"}{" "}
                      $
                    </div>
                  </div>
                  {bookingDetails?.booking?.paymentResult?.isRefunded && (
                    <div className="grid grid-cols-2 ">
                      <div>Refunded Amount</div>
                      <div className="">
                        {bookingDetails?.booking?.paymentResult
                          ?.refundedAmount || "--"}{" "}
                        $
                      </div>
                    </div>
                  )}
                  {bookingDetails?.booking?.paymentResult?.isRefunded && (
                    <div className="grid grid-cols-2 ">
                      <div>Partial Amount</div>
                      <div className="">{getPartialAmount()} $</div>
                    </div>
                  )}
                  {bookingDetails?.details?.classDetails?.isCustomClass && (
                    <div className="grid grid-cols-2 ">
                      <div>Custom Class</div>
                      <div className="">yes</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              Contract Details
            </h2>
          </div>
          <div className="md:ml-3 rounded-md bg-white mb-5 mt-2 dark:bg-secondary-dark-bg text-black-shade dark:text-white-shade px-4 py-1 m-0 w-full">
            <div className="pt-2 w-full pb-5">
              <div className="flex text-xs md:text-sm flex-col">
                <div className="space-y-3 text-base">
                  <div className="grid grid-cols-2 ">
                    <div>First Name</div>
                    <div className="">
                      {bookingDetails?.booking?.contractId?.firstName}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Last Name</div>
                    <div className="">
                      {bookingDetails?.booking?.contractId?.lastName}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Email</div>
                    <div className="">
                      {bookingDetails?.booking?.contractId?.email}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Phone Number</div>
                    <div className="">
                      {bookingDetails?.booking?.contractId?.phoneNumber}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 ">
                    <div>Age</div>
                    <div className="">
                      {bookingDetails?.booking?.contractId?.age}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full mb-4">
            <h2 className="text-4xl items-center justify-center md:justify-start flex w-full font-medium title-font tracking-wide">
              User Sessions
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 w-full">
            {bookingDetails?.sessions?.length > 0 ? (
              bookingDetails.sessions.map((session, i) => (
                <BookingSession
                  key={i}
                  id={session._id}
                  packageName={bookingDetails?.booking?.packageId?.name}
                  date={session?.sessionDate}
                  time={session?.sessionTime}
                  index={i + 1}
                />
              ))
            ) : (
              <div className="flex w-full col-span-3 items-center text-black-shade dark:text-white-shade justify-center">
                <h2 className=" mt-4 mb-8 text-2xl">No Sessions Found</h2>
              </div>
            )}
          </div>
          <CustomModal open={open} handleClose={handleClose}>
            <div className="w-full flex flex-col">
              <div className="py-4 font-semibold">
                Are you sure want to Refund Amount ?
              </div>
              <div className="ml-auto font-semibold">
                <button
                  onClick={handleClose}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
                >
                  No
                </button>
                <button
                  onClick={refundAmount}
                  style={{ backgroundColor: currentColor }}
                  className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Yes
                </button>
              </div>
            </div>
          </CustomModal>
        </>
      )}
    </div>
  );
};

export default BookingDetail;
