import { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { MdEdit, MdDelete } from "react-icons/md";
import { usePostContext } from "../contexts/PostContext";
import { Link } from "react-router-dom";
import CustomModal from "./CustomModal";
import { GoPackage } from "react-icons/go";
const Package = ({
  id,
  packageCategory,
  packageName,
  description,
  price,
  duration,
}) => {
  const { currentColor, currentMode } = useStateContext();
  const { DeletePackage } = usePostContext();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteOpen = () => setDeleteOpen(true);
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const deleteCoupon = async () => {
    handleDeleteClose();
    await DeletePackage(id);
  };

  return (
    <>
      <CustomModal open={deleteOpen} handleClose={handleDeleteClose}>
        <div className="w-full flex flex-col">
          <div className="py-4 font-semibold">
            Are you sure want to delete this Package ?
          </div>
          <div className="ml-auto font-semibold">
            <button
              onClick={handleDeleteClose}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
            >
              No
            </button>
            <button
              onClick={deleteCoupon}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Yes
            </button>
          </div>
        </div>
      </CustomModal>
      <div className="p-2 flex flex-col cursor-default md:w-1/2 w-full">
        <div className="h-full backdrop-blur-3xl bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-5 transition duration-300 flex flex-col w-full items-center justify-center border-black-shade dark:border-white-shade border p-4 rounded-lg">
          <div className="flex items-center gap-x-4 text-black dark:text-white w-full">
            <div className="flex gap-y-3 flex-col">
              <div className="flex items-center gap-x-4">
                <div className="items-center mb-2 flex justify-center">
                  <span
                    style={{ color: currentColor }}
                    className="h-16 w-16 bg-black dark:bg-white border rounded-full flex items-center justify-center"
                  >
                    <GoPackage className="h-10 w-10" />
                  </span>
                </div>
                <div className="flex items-center justify-between flex-grow ">
                  <span className="title-font text-xl font-medium">
                    {packageName}
                  </span>
                  <div className="flex gap-x-3 items-center">
                    <Link
                      to={`/edit-package/${id}`}
                      className="h-10 w-10 flex transition duration-300 items-center justify-center rounded-full hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
                    >
                      <MdEdit className="h-6 w-6" />
                    </Link>
                    <button
                      onClick={handleDeleteOpen}
                      className="h-10 w-10 flex transition duration-300 items-center justify-center rounded-full hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
                    >
                      <MdDelete className="h-6 w-6" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-start flex-grow">
                <h2 className=" w-1/3 title-font text-base line-clamp-2 font-medium">
                  Category :
                </h2>
                <h2 className=" w-2/3 title-font text-base line-clamp-2 font-medium">
                  {packageCategory}
                </h2>
              </div>
              <div className="flex items-start flex-grow">
                <h2 className="w-1/3 title-font text-base line-clamp-2 font-medium">
                  Description :
                </h2>
                <h2 className="w-2/3 title-font text-base line-clamp-2 font-medium">
                  {description}
                </h2>
              </div>
              <div className="flex items-start flex-grow">
                <span className="w-1/3 title-font text-base font-medium">
                  Price :
                </span>
                <span className="w-2/3 title-font text-base font-medium">
                  {price}$
                </span>
              </div>
              <div className="flex items-start flex-grow">
                <span className="w-1/3 title-font text-base font-medium">
                  Duration :
                </span>
                <span className="w-2/3 title-font text-base font-medium">
                  {duration} Minutes
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Package;
